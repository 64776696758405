<script>
  let clazz = "";
  export { clazz as class };
</script>

<div class="default-loader {clazz}">
  <svg width="44px" height="44px" viewBox="0 0 100 100"
    ><circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#85a2b6"
      stroke-width="15"
      r="32"
      stroke-dasharray="150.79644737231007 52.26548245743669"
      ><animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform></circle
    ></svg
  >
</div>

<style>.default-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

svg {
  max-width: 100%;
  height: auto;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9EZWZhdWx0TG9hZGVyLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNFLGFBQWE7RUFDYixtQkFBbUI7RUFDbkIsdUJBQXVCO0FBQ3pCOztBQUNBO0VBQ0UsZUFBZTtFQUNmLFlBQVk7QUFDZCIsImZpbGUiOiJzcmMvRGVmYXVsdExvYWRlci5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbi5kZWZhdWx0LWxvYWRlciB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufVxuc3ZnIHtcbiAgbWF4LXdpZHRoOiAxMDAlO1xuICBoZWlnaHQ6IGF1dG87XG59XG4iXX0= */</style>
