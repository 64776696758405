<section class="background">
  <ul class="background-grid">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
  <div class="background-blurs">
    <div class="blur-1"><span></span></div>
    <div class="blur-2"><span></span></div>
    <div class="blur-3"><span></span></div>
  </div>
</section>

<style>*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
}

.background {
  background: white;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9CYWNrZ3JvdW5kLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTs7O0VBR0Usc0JBQXNCO0FBQ3hCOztBQUNBOztFQUVFLFNBQVM7QUFDWDs7QUFFQTtFQUNFLGlCQUFpQjtFQUNqQixlQUFlO0VBQ2YsWUFBWTtFQUNaLGFBQWE7RUFDYixNQUFNO0FBQ1IiLCJmaWxlIjoic3JjL0JhY2tncm91bmQuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4qLFxuKjo6YmVmb3JlLFxuKjo6YWZ0ZXIge1xuICBib3gtc2l6aW5nOiBib3JkZXItYm94O1xufVxuaHRtbCxcbmJvZHkge1xuICBtYXJnaW46IDA7XG59XG5cbi5iYWNrZ3JvdW5kIHtcbiAgYmFja2dyb3VuZDogd2hpdGU7XG4gIHBvc2l0aW9uOiBmaXhlZDtcbiAgd2lkdGg6IDEwMHZ3O1xuICBoZWlnaHQ6IDEwMHZoO1xuICB0b3A6IDA7XG59XG4iXX0= */</style>
