<script>
  import { onMount, getContext } from "svelte";
  import { key } from "./rightMenu.js";

  export let isDisabled = false;
  export let text = "";
  export let menutype = "";

  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  const { dispatchClick } = getContext(key);

  const handleClick = (e) => {
    if (isDisabled) return;
    dispatch("click");
    dispatchClick(text);
  };
</script>

<div class={menutype} class:disabled={isDisabled} on:click={handleClick}>
  {#if text}
    {text}
  {:else}
    <slot />
  {/if}
</div>

<style>div {
  padding: 4px 15px;
  cursor: default;
  font-size: 14px;
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

div.sub {
  padding-left: 30px;
}

div.parent:hover,
div.sub:hover {
  background: #0002;
}

div.disabled {
  color: #0006;
}

div.disabled:hover {
  background: white;
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9SaWdodE1lbnVPcHRpb24uc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsaUJBQWlCO0VBQ2pCLGVBQWU7RUFDZixlQUFlO0VBQ2YsYUFBYTtFQUNiLG1CQUFtQjtFQUNuQixhQUFhO0FBQ2Y7O0FBQ0E7RUFDRSxrQkFBa0I7QUFDcEI7O0FBQ0E7O0VBRUUsaUJBQWlCO0FBQ25COztBQUNBO0VBQ0UsWUFBWTtBQUNkOztBQUNBO0VBQ0UsaUJBQWlCO0FBQ25CIiwiZmlsZSI6InNyYy9SaWdodE1lbnVPcHRpb24uc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG5kaXYge1xuICBwYWRkaW5nOiA0cHggMTVweDtcbiAgY3Vyc29yOiBkZWZhdWx0O1xuICBmb250LXNpemU6IDE0cHg7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gIGdyaWQtZ2FwOiA1cHg7XG59XG5kaXYuc3ViIHtcbiAgcGFkZGluZy1sZWZ0OiAzMHB4O1xufVxuZGl2LnBhcmVudDpob3ZlcixcbmRpdi5zdWI6aG92ZXIge1xuICBiYWNrZ3JvdW5kOiAjMDAwMjtcbn1cbmRpdi5kaXNhYmxlZCB7XG4gIGNvbG9yOiAjMDAwNjtcbn1cbmRpdi5kaXNhYmxlZDpob3ZlciB7XG4gIGJhY2tncm91bmQ6IHdoaXRlO1xufVxuIl19 */</style>
